<template>
  <div :class="this.isMobile ? 'mobile resources box' : 'resources box'">
    <p class="title is-4 is-spaced">
      Resources for Accredited Investors
    </p>

    <div class="additional-links content is-medium">
      <p>
        <a href="https://www.sec.gov/news/press-release/2020-191" target="_blank">
          Recent Changes to SEC Definition of Accredited Investor
        </a>
      </p>

      <p>
        <a href="https://equitymultiple.com/blog/modern-portfolio-theory?utm_source=aia&utm_campaign=owned-media&utm_medium=referral&utm_content=accredited" target="_blank">
          Modern Portfolio Theory - Applications for Accredited Investors
        </a>
      </p>

      <p>
        <a href="https://equitymultiple.com/self-directed-ira-real-estate-investing?utm_source=aia&utm_campaign=owned-media&utm_medium=referral&utm_content=accredited" target="_blank">
          Investing in Alternatives via an IRA
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'resources-page',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
  a {
    text-decoration: underline;
  }

  .resources {
    margin-top: 100px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .resources.mobile {
    margin-top: 40px;
  }

  .additional-links {
    margin-top: 40px;
  }

  .additional-links p {
    margin-top: 60px;
  }
</style>
