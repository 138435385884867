<template>
  <div>
    Combined, you and your spouse earned

    <b-select
      v-model="spouseEarningPastTwoYear"
      @input="setValue"
    >
      <option value=""></option>
      <option value="same">At least this much</option>
      <option value="slightly_less">Slightly less than this</option>
      <option value="substantially_less">Substantially less than this</option>
    </b-select>

    in the past two full calendar years
  </div>
</template>

<script>
export default {
  name: 'spouse-earning-past-two-year',
  props: {
    currentValue: {
      type: String,
      default: ''
    },
    setSpouseEarningPastTwoYear: {
      type: Function
    }
  },
  data() {
    return {
      spouseEarningPastTwoYear: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setSpouseEarningPastTwoYear) {
        this.setSpouseEarningPastTwoYear(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.spouseEarningPastTwoYear = this.currentValue;
    }
  }
};
</script>

<style scoped>
  .control {
    display: inline-flex;
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
