import { render, staticRenderFns } from "./salary-past-two-year.vue?vue&type=template&id=70c2d9fe&scoped=true&"
import script from "./salary-past-two-year.vue?vue&type=script&lang=js&"
export * from "./salary-past-two-year.vue?vue&type=script&lang=js&"
import style0 from "./salary-past-two-year.vue?vue&type=style&index=0&id=70c2d9fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c2d9fe",
  null
  
)

export default component.exports