const CITIZENSHIP = {
  usa: 'The United States',
  other: 'A country other than the United States'
};

const TAX_ID_THROUGH = {
  llc: 'An LLC that I set up for investing purposes, incorporated in the United States',
  trust_or_entity: 'A trust or other entity incorporated in the United States',
  legal_resident: 'Because I am a legal resident of the U.S.',
  none: 'I do not have a valid U.S. tax ID'
};

const SALARY_PAST_TWO_YEAR = {
  same: 'At least this much',
  slightly_less: 'Slightly less than this',
  substantially_less: 'Substantially less than this'
};

const MARRIED = {
  yes: 'Yes',
  no: 'No'
};

export {
  CITIZENSHIP,
  TAX_ID_THROUGH,
  SALARY_PAST_TWO_YEAR,
  MARRIED
};
