<template>
  <div :class="this.isMobile ? 'mobile result box' : 'result box'">
    <b-icon
      icon="cancel"
      custom-class="custom-cancel-icon"
      size="is-large"
      type="is-danger">
    </b-icon>

    <div class="result-text">
      <p class="title is-4 is-spaced">
        You are not yet an Accredited Investor
      </p>

      <p class="subtitle is-5">
        But you may still find alternatives assets worth vesting in.
      </p>
    </div>

    <div class="redo-btn">
      <b-button
        type="is-warning"
        @click="handleRedo()"
      >
        Redo
      </b-button>
    </div>

    <div class="additional-links content is-medium">
      <p>
        <a href="https://www.fundrise.com?utm_source=aia" target="_blank">
          Access Private Real Estate Investing
          for Non-Accredited Investors
        </a>
      </p>

      <p>
        <a href="https://yieldtalk.com/crowdfunding-investment-websites?utm_source=aia" target="_blank">
          Explore Non-Accredited Alternative Investment Platforms
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-accredited',
  props: {
    setShowResult: {
      type: Function
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleRedo() {
      if (this.setShowResult) {
        this.setShowResult(false);
      }
    }
  }
};
</script>

<style scoped>
  .result {
    margin-top: 100px;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .mobile.result {
    margin-top: 40px;
  }

  .result-text {
    font-weight: 400;
    font-size: 24px;
  }

  .redo-btn {
    margin-top: 40px;
  }

  .additional-links {
    margin-top: 40px;
  }

  .additional-links p {
    margin-top: 20px;
  }
</style>
