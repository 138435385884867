<template>
  <div :class="this.isMobile ? 'mobile inline-slider' : 'inline-slider'">
    <div class="q-text">
      My current annual salary is
    </div>

    <b-slider
      :step="10000"
      indicator
      :tooltip="false"
      :max="300000"
      v-model="currentAnnualSalary"
      :custom-formatter="val => '$' + (val === 300000 ? '300k+' : (val / 1000 + 'k'))"
      @change="setValue"
    ></b-slider>
  </div>
</template>

<script>
export default {
  name: 'current-annual-salary',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    currentValue: {
      type: Number,
      default: 100000
    },
    setCurrentAnnualSalary: {
      type: Function
    }
  },
  data() {
    return {
      currentAnnualSalary: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setCurrentAnnualSalary) {
        this.setCurrentAnnualSalary(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.currentAnnualSalary = this.currentValue;
    }
  }
};
</script>

<style scoped>
  .inline-slider {
    display: flex;
    justify-content: center;
  }

  .inline-slider .q-text {
    margin-right: 20px;
  }

  .inline-slider .b-slider {
    width: 64%;
    margin-top: 1.8rem;
  }

  .mobile.inline-slider {
    display: block;
  }

  .mobile .inline-slider .b-slider {
    width: 100%;
  }
</style>
