/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import TurbolinksAdapter from 'vue-turbolinks';

import Vue from 'vue';
import Buefy from 'buefy';

import '@mdi/font/css/materialdesignicons.css';
import 'buefy/dist/buefy.css';

import App from '../app';
// import Home from '../pages/home/index.vue';
// import AppFooter from '../components/app-footer';

Vue.use(TurbolinksAdapter);
Vue.use(Buefy);

// document.addEventListener('turbolinks:request-start', (event) => {
//   const { xhr } = event.data;
//   xhr
//     .setRequestHeader('X-Turbolinks-Nonce', document.querySelector("meta[name='csp-nonce']")
//     .getAttribute('content'));
// });

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    render: (h) => h(App)
  }).$mount();
  document.body.appendChild(app.$el);
});


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
