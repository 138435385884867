<template>
  <div id="home" :class="isMobile && 'mobile'">
    <transition name="fade">
      <div v-if="showResult">
        <Accredited
          :setShowResult="setShowResult"
          :isMobile="this.isMobile"
          :subscribed="this.subscribed"
          :setSubscribed="setSubscribed"
          v-if="isAccredited"
        />
        <NotAccredited :setShowResult="setShowResult" :isMobile="this.isMobile" v-else />
      </div>

      <div v-else>
        <h2 class="title is-3">Am I An Accredited Investor?</h2>

        <div id="home-aia_form">
          <div>
            I am
            <b-input
              :value="this.age"
              @input="value => setValue('age', value)"
            ></b-input>

            years old, and a citizen of

            <Citizenship
              :currentValue="citizenship"
              :setCitizenship="value => setValue('citizenship', value)"
            />
          </div>

          <transition name="fade">
            <TaxIdThrough
              :currentValue="taxIdThrough"
              :setTaxIdThrough="value => setValue('taxIdThrough', value)"
              v-if="this.citizenship == 'other'"
            />
          </transition>

          <EstNetWorth
            :currentValue="estNetWorth"
            :setEstNetWorth="value => setValue('estNetWorth', value)"
          />

          <div v-if="estNetWorth < 1000000">
            <transition name="fade">
              <CurrentAnnualSalary
                :currentValue="currentAnnualSalary"
                :setCurrentAnnualSalary="value => setValue('currentAnnualSalary', value)"
                :isMobile="this.isMobile"
              />
            </transition>

            <transition name="fade">
              <SalaryPastTwoYear
                :currentValue="salaryPastTwoYear"
                :setSalaryPastTwoYear="value => setValue('salaryPastTwoYear', value)"
                v-if="currentAnnualSalary >= 200000"
              />
            </transition>

            <div v-if="currentAnnualSalary > 100000 && currentAnnualSalary < 200000">
              <transition name="fade">
                <Married
                  :currentValue="married"
                  :setMarried="value => setValue('married', value)"
                />
              </transition>

              <transition name="fade">
                <SpouseEarning
                  :currentValue="spouseEarning"
                  :setSpouseEarning="value => setValue('spouseEarning', value)"
                  :isMobile="this.isMobile"
                  v-if="married == 'yes'"
                />
              </transition>

              <transition name="fade">
                <SpouseEarningPastTwoYear
                  :currentValue="spouseEarningPastTwoYear"
                  :setSpouseEarningPastTwoYear="val => setValue('spouseEarningPastTwoYear', val)"
                  v-if="married == 'yes' && (currentAnnualSalary + spouseEarning) >= 300000"
                />
              </transition>
            </div>
          </div>

          <div class="errors" v-if="isErrored">
            <p class="subtitle is-6 is-spaced has-text-danger">
              {{this.errorMessage}}
            </p>
          </div>

          <div id="submit-btn">
            <b-button
              type="is-primary"
              @click="getResult()"
            >
            Click here to view the result
            </b-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Citizenship from './components/form/citizenship';
import TaxIdThrough from './components/form/tax-id';
import EstNetWorth from './components/form/est-net-worth';
import CurrentAnnualSalary from './components/form/current-annual-salary';
import SalaryPastTwoYear from './components/form/salary-past-two-year';
import Married from './components/form/married';
import SpouseEarning from './components/form/spouse-earning';
import SpouseEarningPastTwoYear from './components/form/spouse-earning-past-two-year';

import Accredited from './components/accredited';
import NotAccredited from './components/not-accredited';

import runAccreditationDecisioning from '../../utils/accreditation-decisioning';

export default {
  name: 'homepage',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Citizenship,
    TaxIdThrough,
    EstNetWorth,
    CurrentAnnualSalary,
    SalaryPastTwoYear,
    Married,
    SpouseEarning,
    SpouseEarningPastTwoYear,
    Accredited,
    NotAccredited
  },
  data() {
    return {
      age: '',
      citizenship: '',
      taxIdThrough: '',
      currentAnnualSalary: 100000,
      salaryPastTwoYear: '',
      estNetWorth: 1200000,
      married: '',
      spouseEarning: 100000,
      spouseEarningPastTwoYear: '',

      isAccredited: false,
      showResult: false,
      isErrored: false,
      errorMessage: '',

      subscribed: false
    };
  },

  methods: {
    setValue(fieldName, fieldValue) {
      this[fieldName] = fieldValue;
      this.saveData();
    },

    getResult() {
      if (this.validateForm()) {
        this.setIsAccredited();
        this.saveClickedGetResults();
      }
    },

    setShowResult(value) {
      this.showResult = value;

      if (value === false) {
        this.saveClickedRedoButton();
      }
    },

    validateForm() {
      const {
        age, citizenship, taxIdThrough
      } = this;

      /* eslint-disable-next-line use-isnan */
      if (!age || Number(age) === NaN || Number(age) < 16) {
        this.isErrored = true;
        this.errorMessage = 'Incomplete form: Please add a valid age';
        return false;
      }

      if (!citizenship && !citizenship.length) {
        this.isErrored = true;
        this.errorMessage = 'Incomplete form: Please select citizenship status';
        return false;
      }

      if (citizenship === 'other' && !(taxIdThrough || taxIdThrough.length)) {
        this.isErrored = true;
        this.errorMessage = 'Incomplete form: Please select valid U.S. tax ID through';
        return false;
      }

      this.isErrored = false;
      this.errorMessage = '';
      return true;
    },

    saveData() {
      const {
        age, citizenship, taxIdThrough, currentAnnualSalary, salaryPastTwoYear,
        estNetWorth, married, spouseEarning, spouseEarningPastTwoYear, subscribed
      } = this;

      const storage = window.localStorage;
      if (!storage) { return; }
      storage.setItem('aia-progress', JSON.stringify({
        age,
        citizenship,
        taxIdThrough,
        currentAnnualSalary,
        salaryPastTwoYear,
        estNetWorth,
        married,
        spouseEarning,
        spouseEarningPastTwoYear,
        subscribed
      }));
    },

    saveClickedGetResults() {
      const storage = window.localStorage;
      if (!storage) { return; }
      let savedData = storage.getItem('aia-progress');
      if (savedData) {
        savedData = JSON.parse(savedData);
        savedData.clickedGetResults = true;
        storage.setItem('aia-progress', JSON.stringify(savedData));
      }
    },

    saveClickedRedoButton() {
      const storage = window.localStorage;
      if (!storage) { return; }
      let savedData = storage.getItem('aia-progress');
      if (savedData) {
        savedData = JSON.parse(savedData);
        savedData.clickedGetResults = false;
        storage.setItem('aia-progress', JSON.stringify(savedData));
      }
    },

    setIsAccredited() {
      this.isAccredited = runAccreditationDecisioning({
        age: this.age,
        citizenship: this.citizenship,
        taxIdThrough: this.taxIdThrough,
        currentAnnualSalary: this.currentAnnualSalary,
        salaryPastTwoYear: this.salaryPastTwoYear,
        estNetWorth: this.estNetWorth,
        married: this.married,
        spouseEarning: this.spouseEarning,
        spouseEarningPastTwoYear: this.spouseEarningPastTwoYear
      });

      this.setShowResult(true);
    },

    setSubscribed(newValue) {
      this.subscribed = newValue;
    }
  },

  mounted() {
    const storage = window.localStorage;
    if (!storage) { return; }
    let savedData = storage.getItem('aia-progress');
    if (savedData) {
      savedData = JSON.parse(savedData);
      this.age = savedData.age;
      this.citizenship = savedData.citizenship;
      this.taxIdThrough = savedData.taxIdThrough;
      this.currentAnnualSalary = savedData.currentAnnualSalary;
      this.salaryPastTwoYear = savedData.salaryPastTwoYear;
      this.estNetWorth = savedData.estNetWorth;
      this.married = savedData.married;
      this.spouseEarning = savedData.spouseEarning;
      this.spouseEarningPastTwoYear = savedData.spouseEarningPastTwoYear;
      this.subscribed = savedData.subscribed;

      if (savedData.clickedGetResults) {
        this.setIsAccredited();
      }
    }
  },

  watch: {
    // age() {
    //   this.setIsAccredited();
    // },
    // citizenship() {
    //   this.setIsAccredited();
    // },
    // taxIdThrough() {
    //   this.setIsAccredited();
    // },
    // currentAnnualSalary() {
    //   this.setIsAccredited();
    // },
    // salaryPastTwoYear() {
    //   this.setIsAccredited();
    // },
    // estNetWorth() {
    //   this.setIsAccredited();
    // },
    // married() {
    //   this.setIsAccredited();
    // },
    // spouseEarning() {
    //   this.setIsAccredited();
    // },
    // spouseEarningPastTwoYear() {
    //   this.setIsAccredited();
    // }
  }
};
</script>

<style scoped>
  #home {
    margin-top: 40px;
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  #home-aia_form {
    line-height: 4rem;
    font-size: 20px;
    font-weight: 300;
    margin-top: 40px;
  }

  .mobile #home-aia_form {
    line-height: 3.5rem;
  }

  .control {
    display: inline-flex;
    margin-left: 10px;
    margin-right: 10px;
  }

  .errors {
    margin-top: 80px;
  }

  #submit-btn {
    margin-top: 80px;
  }
</style>
