<template>
  <div :class="this.isMobile ? 'mobile about' : 'about'">
    <p class="title is-3 is-spaced">
      About
    </p>

    <p class="subtitle is-5">
      Am I Accredited is a project built by EquityMultiple,
      the folks who bring you simple, accessible, transparent real estate investing.
      We wanted to create a free utility for self-directed investors to understand,
      at a glance, their accredited investor status, no strings attached.
    </p>

    <p class="subtitle is-spaced is-5">
      Want to get in touch?
      Please don’t hesitate to <a href="mailto:help@equitymultiple.com">reach out</a>.
    </p>

    <p class="pp title is-spaced is-5">
      Privacy Policy
    </p>

    <p class="subtitle is-5">
      This site uses cookies. We use cookies to ensure a great experience
       and to analyze website traffic. Site usage data may be shared with
       our analytics partners. Should you provide your email address—the
       only piece of personal information we ask for—we will never share
       this information with any third parties.
    </p>
  </div>
</template>

<script>
export default {
  name: 'about-page',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
  .about {
    margin-top: 100px;
  }

  .about.mobile {
    margin-top: 40px;
  }

  .pp {
    margin-top: 60px;
  }
</style>
