<template>
  <div class="container">
    <b-navbar>
      <template #brand>
        <b-navbar-item href="/" style="font-size: 30px; color:#7957d5; font-weight: 800">
            <!-- <img
              :src="require('images/aia-logo.jpeg')"
              alt="logo"
            > -->
            AIA
        </b-navbar-item>
      </template>

      <template #start>
      </template>

      <template #end>
        <b-navbar-item href="/resources" :active="activeNav() === 'resources'">
          Resources
        </b-navbar-item>

        <b-navbar-item href="/about" :active="activeNav() === 'about'">
          About
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'app-header',
  methods: {
    activeNav() {
      let currentNav = '/';
      const { href } = window.location;
      if (href.indexOf('resources') !== -1) {
        currentNav = 'resources';
      } else if (href.indexOf('about') !== -1) {
        currentNav = 'about';
      }

      return currentNav;
    }
  }
};
</script>
