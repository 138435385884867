<template>
  <div :class="this.isMobile ? 'mobile result box' : 'result box'">
    <b-icon
      icon="trophy"
      custom-class="custom-trophy-icon"
      size="is-large"
      type="is-success">
    </b-icon>

    <div class="result-text">
      <p class="title is-4 is-spaced">
        Congratulations! You qualify as an Accredited Investor
      </p>

      <p class="subtitle is-5">
        Accredited investors are eligible to participate in a broad
        set of alternative investments through web platforms.
      </p>
    </div>

    <div class="subscribe-section" v-if="!this.subscribed">
      <p class="subtitle is-5">
        Want to receive accredited investor news?
      </p>

      <div class="subscribe-form">
        <b-field
          :type="this.emailInvalid ? 'is-danger' : ''"
        >
          <b-input type="email"
            custom-class="subscribe-email__field"
            placeholder="Enter your email"
            @input="setSubscribeEmail"
          >
          </b-input>
        </b-field>

        <b-button
          type="is-primary"
          :loading="this.saving"
          :disabled="this.saving"
          @click="handleSubscribe()"
        >
          Subscribe
        </b-button>
      </div>
    </div>

    <div class="additional-links content is-medium">
      <b-button
        tag="a"
        type="is-success"
        href="https://equitymultiple.com?utm_source=aia&utm_campaign=owned-media&utm_medium=referral&utm_content=accredited"
        :class="this.isMobile && 'btn-mobile btn-overflow'"
      >
        Access Exclusive, Private Real Estate Investments
      </b-button>

      <p>
        <a href="https://equitymultiple.com/blog?utm_source=aia&utm_campaign=owned-media&utm_medium=referral&utm_content=accredited" target="_blank">Curated Investor Resources</a>
      </p>

      <p>
        <a href="https://yieldtalk.com/crowdfunding-investment-websites?utm_source=aia" target="_blank">Explore Accredited-Only Investment Platforms</a>
      </p>
    </div>

    <div class="redo-btn">
      <b-button
        type="is-warning"
        @click="handleRedo()"
      >
        Redo
      </b-button>
    </div>
  </div>
</template>

<script>
import getData from '../../../utils/subscribe-data';

export default {
  name: 'accredited',
  props: {
    setShowResult: {
      type: Function
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    subscribed: {
      type: Boolean,
      default: false
    },
    setSubscribed: {
      type: Function
    }
  },
  data() {
    return {
      subscribeEmail: '',
      saving: false,
      emailInvalid: false
    };
  },
  methods: {
    handleRedo() {
      if (this.setShowResult) {
        this.setShowResult(false);
      }
    },

    setSubscribeEmail(val) {
      this.subscribeEmail = val;
      if (this.validateEmail()) {
        this.emailInvalid = false;
      } else {
        this.emailInvalid = true;
      }
    },

    handleSubscribe() {
      this.saving = true;
      const email = this.subscribeEmail;

      if (this.validateEmail()) {
        this.emailInvalid = false;

        // send data to aia server
        const storage = window.localStorage;
        if (!storage) { return; }
        let savedData = storage.getItem('aia-progress');
        if (savedData) {
          savedData = JSON.parse(savedData);
          savedData.email = email;

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...getData(savedData), email })
          };
          const apiUrl = `${window.location.origin}/api/v1/lead`;
          fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then(() => {
              this.setSubscribed(true);

              savedData.subscribed = true;
              storage.setItem('aia-progress', JSON.stringify(savedData));

              this.success('Successfully subscribed to receive accredited investor news!');
              this.saving = false;
            });
        }
      } else {
        this.emailInvalid = true;
        this.saving = false;
      }
    },

    validateEmail() {
      /* eslint-disable-next-line no-useless-escape */
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.subscribeEmail);
    },

    success(message) {
      this.$buefy.toast.open({
        message,
        type: 'is-success'
      });
    },

    danger(error) {
      this.$buefy.toast.open({
        message: error,
        type: 'is-danger'
      });
    }
  }
};
</script>

<style scoped>
  a {
    text-decoration: underline;
  }

  .result {
    margin-top: 100px;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .result.mobile {
    margin-top: 40px;
  }

  .subscribe-section {
    margin-top: 20px;
  }

  .subscribe-section .subscribe-form {
    width: 360px;
    margin: auto;
    display: inline-flex;
  }

  .subscribe-form .field, .subscribe-form .control {
    width: 100%;
  }

  .additional-links {
    margin-top: 20px;
  }

  .additional-links p {
    margin-top: 20px;
  }

  .redo-btn {
    margin-top: 40px;
  }

  .btn-mobile.btn-overflow {
    white-space: unset;
    height: 80px;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
</style>
