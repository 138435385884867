<template>
  <div>
    I am married

    <b-select
      @input="setValue"
      v-model="married"
    >
      <option value=""></option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: 'married',
  props: {
    currentValue: {
      type: String,
      default: ''
    },
    setMarried: {
      type: Function
    }
  },
  data() {
    return {
      married: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setMarried) {
        this.setMarried(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.married = this.currentValue;
    }
  }
};
</script>

<style scoped>
  .control {
    display: inline-flex;
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
