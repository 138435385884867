<template>
  <b-select
    @input="setValue"
    v-model="citizenship"
  >
    <option value=""></option>
    <option value="usa">The United States</option>
    <option value="other">A country other than the United States</option>
  </b-select>
</template>

<script>
export default {
  name: 'citizenship',
  props: {
    currentValue: {
      type: String,
      default: ''
    },
    setCitizenship: {
      type: Function
    }
  },
  data() {
    return {
      citizenship: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setCitizenship) {
        this.setCitizenship(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.citizenship = this.currentValue;
    }
  }
};
</script>

<style scoped>
  .control {
    display: inline-flex;
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
