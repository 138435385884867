<template>
  <div>
    My estmated net worth, excluding my primary residence is

    <b-slider
      :step="100000"
      indicator
      :tooltip="false"
      :max="2500000"
      :min="100000"
      v-model="estNetWorth"
      :custom-formatter
        ="val => '$' + (
          (val === 2500000) ? '2.5M+'
                            : (val >= 1000000 ? (val / 1000000) + 'M' : (val / 1000) + 'k'))"
      @change="setValue"
    ></b-slider>
  </div>
</template>

<script>
export default {
  name: 'est-net-worth',
  props: {
    currentValue: {
      type: Number,
      default: 1200000
    },
    setEstNetWorth: {
      type: Function
    }
  },
  data() {
    return {
      estNetWorth: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setEstNetWorth) {
        this.setEstNetWorth(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.estNetWorth = this.currentValue;
    }
  }
};
</script>
