<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <a href="https://twitter.com/EquityMultiple" target="_blank">
        <b-icon
          icon="twitter"
          size="is-large"
        ></b-icon>
      </a>

      <a href="https://www.linkedin.com/company/equity-multiple" target="_blank">
        <b-icon
          icon="linkedin"
          size="is-large"
        ></b-icon>
      </a>

      <a href="https://www.instagram.com/equitymultiple_com" target="_blank">
        <b-icon
          icon="instagram"
          size="is-large"
          class="instagram-icon"
        ></b-icon>
      </a>

      <a href="https://www.facebook.com/equitymultiple" target="_blank">
        <b-icon
          icon="facebook"
          size="is-large"
          class="facebook-icon"
        ></b-icon>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer'
};
</script>

<style scoped>
  .footer {
    position: absolute;
    left: 0;
    bottom: -360px;
    width: 100%;
    background: none;
    padding: 3rem 1.5rem 6rem;
  }

  span.icon {
    margin-right: 40px;
  }

  span.icon.facebook-icon {
    margin-right: 0;
  }
</style>
