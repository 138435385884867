<template>
  <div>
    I have a current, valid U.S. tax ID through

    <b-select
      v-model="taxIdThrough"
      @input="setValue"
    >
      <option value=""></option>
      <option value="llc">
        An LLC that I set up for investing purposes, incorporated in the United States
      </option>
      <option value="trust_or_entity">
        A trust or other entity incorporated in the United States
      </option>
      <option value="legal_resident">
        Because I am a legal resident of the U.S.
      </option>
      <option value="none">
        I do not have a valid U.S. tax ID
      </option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: 'tax-id',
  props: {
    currentValue: {
      type: String,
      default: ''
    },
    setTaxIdThrough: {
      type: Function
    }
  },
  data() {
    return {
      taxIdThrough: this.currentValue
    };
  },
  methods: {
    setValue(value) {
      if (this.setTaxIdThrough) {
        this.setTaxIdThrough(value);
      }
    }
  },
  watch: {
    currentValue() {
      this.taxIdThrough = this.currentValue;
    }
  }
};
</script>

<style scoped>
  .control {
    display: inline-flex;
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
