<template>
  <div id="app">
    <AppHeader />

    <div class="container is-fluid content-wrapper">
      <component v-bind:is="cmpName()" :isMobile="isMobile()"></component>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/app-header';
import HomePage from './pages/home/index';
import ResourcesPage from './pages/resources/index';
import AboutPage from './pages/about/index';
import AppFooter from './components/app-footer';

export default {
  name: 'app',
  components: {
    AppHeader, HomePage, ResourcesPage, AboutPage, AppFooter
  },
  data() {
    return {};
  },
  methods: {
    cmpName() {
      let cmp = 'HomePage';
      const { href } = window.location;
      if (href.indexOf('resources') !== -1) {
        cmp = 'ResourcesPage';
      } else if (href.indexOf('about') !== -1) {
        cmp = 'AboutPage';
      }

      return cmp;
    },

    isMobile() {
      return window.screen.width < 440;
    }
  }
};
</script>

<style scoped>
  #app {
    position: relative;
  }

  /*.content-wrapper {
    min-height: 400px;
  }*/

  .content-wrapper {
    max-width: 1344px !important;
  }
</style>
