/*
* This is dependent on logic used in accreditation-decisioning.js
* If you are changing the logic then change it here too
*
* These are the fields that gets saved to google sheet on subscribe

Params dictionary

  age: "",
  citizenship: "usa", // [usa, other]
  taxIdThrough: "llc", // [llc, trust_or_entity, legal_resident, none]
  currentAnnualSalary: 120000, //[max 300k]
  salaryPastTwoYear: "same", // [same, slightly_less, substantially_less]
  estNetWorth: 520000, // max 2.5M
  married: "no", // [yes, no]
  spouseEarning: 100000,
  spouseEarningPastTwoYear: "same" // [same, slightly_less, substantially_less]
*/

import {
  CITIZENSHIP,
  TAX_ID_THROUGH,
  SALARY_PAST_TWO_YEAR,
  MARRIED
} from '../pages/home/constants';

const getData = (data) => {
  const {
    age,
    citizenship,
    estNetWorth,
    taxIdThrough,
    currentAnnualSalary,
    salaryPastTwoYear,
    married,
    spouseEarning,
    spouseEarningPastTwoYear
  } = data;

  const retVal = {
    age,
    citizenship: CITIZENSHIP[citizenship],
    estNetWorth
  };

  if (citizenship === 'other') {
    retVal.taxIdThrough = TAX_ID_THROUGH[taxIdThrough];
  }

  if (estNetWorth < 1000000) {
    if (currentAnnualSalary >= 200000) {
      retVal.currentAnnualSalary = currentAnnualSalary;
      retVal.salaryPastTwoYear = SALARY_PAST_TWO_YEAR[salaryPastTwoYear];
    } else {
      retVal.married = MARRIED[married];

      if (married === 'yes') {
        retVal.spouseEarning = spouseEarning;
        retVal.spouseEarningPastTwoYear = SALARY_PAST_TWO_YEAR[spouseEarningPastTwoYear];
      }
    }
  }

  return retVal;
};

export default getData;
