/*
  Params dictionary

  age: "",
  citizenship: "usa", // [usa, other]
  taxIdThrough: "llc", // [llc, trust_or_entity, legal_resident, none]
  currentAnnualSalary: 120000, //[max 300k]
  salaryPastTwoYear: "same", // [same, slightly_less, substantially_less]
  estNetWorth: 520000, // max 2.5M
  married: "no", // [yes, no]
  spouseEarning: 100000,
  spouseEarningPastTwoYear: "same" // [same, slightly_less, substantially_less]
*/
const run = (params) => {
  const {
    age,
    citizenship,
    estNetWorth,
    taxIdThrough,
    currentAnnualSalary,
    salaryPastTwoYear,
    married,
    spouseEarning,
    spouseEarningPastTwoYear
  } = params;

  if (citizenship === 'other' && (taxIdThrough === 'none' || taxIdThrough === '')) {
    return false;
  }

  if (estNetWorth && estNetWorth >= 1000000) {
    if (age && citizenship) {
      return true;
    }
  }

  if (estNetWorth && estNetWorth < 1000000) {
    if (currentAnnualSalary >= 200000) {
      if (salaryPastTwoYear === 'same') {
        return true;
      }

      if (salaryPastTwoYear === 'slightly_less' && currentAnnualSalary >= 250000) {
        return true;
      }

      if (salaryPastTwoYear === 'substantially_less') {
        return false;
      }
    }

    if (currentAnnualSalary > 100000 && currentAnnualSalary < 200000) {
      if (married === 'no') {
        return false;
      }

      if (married === 'yes') {
        const combinedEarning = currentAnnualSalary + spouseEarning;

        if (combinedEarning >= 300000) {
          if (spouseEarningPastTwoYear === 'same') {
            return true;
          }

          if (spouseEarningPastTwoYear === 'slightly_less' && combinedEarning >= 350000) {
            return true;
          }

          if (spouseEarningPastTwoYear === 'substantially_less') {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  }

  return undefined;
};

export default run;
